import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-avatar',
  standalone: true,
  imports: [],
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.css',
})
export class AvatarComponent {
  @Input() name: string = '';

  get initials(): string {
    if (this.name) {
      const names = this.name.split(' ');
      return names.length > 1 ? names[0][0] + names[1][0] : names[0][0];
    } else {
      return '';
    }
  }
}
